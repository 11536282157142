import React, { useState, useEffect, useRef } from 'react';
import './App.css';

const BackButton = ({ onClick, label }) => {
  return (
    <button
      onClick={onClick}
      className="flex items-center text-left space-x-2 font-medium text-1xl font-bold text-black"
    >
      {onClick&&(<span className="text-lg">&lt;</span>)}
      <span>{label}</span>
    </button>
  );
};

const Logo = () => {
  return (
    <div className="justify-end items-end mb-2">
      <img src="/assets/mdl-logo-circle-bg-white.png" alt="Logo" className="max-w-full h-auto" style={{ maxWidth: '50px' }}/>
    </div>
  );
};

class MainHeader extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="fixed text-left justify-center items-center top-0 left-0 right-0 bg-white border-2 border-gray-200 z-10">
        <div className="container text-left justify-center items-center bg-white p-4 mx-auto max-w-screen-md">
          <div className="flex flex-col text-left items-left min-h-[45px] justify-center bg-white max-h-12">
            <div className="flex items-center justify-between px-4 py-2">
              <BackButton onClick={this.props.onClick} label={this.props.label} />
              <Logo />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MainHeader;