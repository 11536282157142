//import logo from './logo.svg';

import React, { useState, useEffect, useRef } from 'react';

import ModalMessage from './ModalMessage'; 
import Loading      from './Loading';
import ConfirmModal from './ConfirmModal-password';

import * as utils from './Utils';
import { MD5 } from 'crypto-js';

import './App.css';

window.addEventListener( "pageshow", function ( event ) {
  var historyTraversal = event.persisted || ( typeof window.performance != "undefined" &&  window.performance.navigation.type === 2 );
  if ( historyTraversal ) { window.location.reload(); } });

const Logo = () => {

  return (
    <div className="flex justify-center items-center mb-2">
    </div>
  );
};

const fetchStates = async () => {

  try {

    const response = await fetch(process.env.REACT_APP_API_ENDPOINT + '/data/states/', {
      method: 'GET',
      headers: {
        Pragma: 'no-cache',
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
    });

    if (!response.ok)
      throw new Error(response.message);

    const data = await response.json();
    return data;
  }
  catch (error) {

    console.error('Error fetching states: ', error);
    throw error;
  }
};

const fetchCities = async (state) => {

  try {

    const response = await fetch(process.env.REACT_APP_API_ENDPOINT + '/data/states/' + state, {
      method: 'GET',
      headers: {
        Pragma: 'no-cache',
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
    });

    if (!response.ok)
      throw new Error(response.message);

    const data = await response.json();
    return data;
  }
  catch (error) {

    console.error('Error fetching states: ', error);
    throw error;
  }
};

function App() {

  const [loading,  setLoading ] = useState(true);
  const [formData, setFormData] = useState({
    cpf: '',
    nickname: '',
    name: '',
    email: '',
    mobile: '',
    birthdate: '1990-01-01',
    sex: '',
    state: '',
    city: '',
    pass: '',
    userUUID: ''
  });

  const [modalMessage,  setModalMessage] = useState(null);
  const [modalCallback, setModalCalback] = useState(null);

  const [stateOptions, setStateOptions] = useState(['RS']);
  const [cityOptions,  setCityOptions]  = useState(['Porto Alegre']);

  const [isConfirmModalOpen, setConfirmModalOpen] = useState(false);

  const [isCPFValid, setIsCPFValid] = useState(false);

  const cpfRef       = useRef(null);
  const nicknameRef  = useRef(null);
  const nameRef      = useRef(null);
  const emailRef     = useRef(null);
  const mobileRef    = useRef(null);
  const birthdateRef = useRef(null);
  const sexRef       = useRef(null);
  const stateRef     = useRef(null);
  const cityRef      = useRef(null);

  const [lastCPF, setLastCPF] = useState('');

  const [nextCityLookup, setNextCityLookup] = useState('');

  const eventUUID = localStorage.getItem('eventUUID');
  const cartUUID  = localStorage.getItem('cartUUID');

  useEffect(() => {

    const loadStates = async () => {

      try {

        {
          const result = await fetchStates();
          if (result != null && result.states != null && result.states.length > 0)
            setStateOptions(result.states);
          else
            throw new Error('Could not retrieve states information');
        }

        formData.state = 'RS';

        {
          const result = await fetchCities('RS');
          if (result != null && result.cities != null && result.cities.length > 0)
            setCityOptions(result.cities);
          else
            throw new Error('Could not retrieve cities information');
        }

        formData.city = 'Porto Alegre';
      }
      catch (error) {

        console.log("Error: " + error.message);
      }
    }

    loadStates();

    setLoading(false);

    setTimeout(() => { 

      const mdlCPF = localStorage.getItem('mdlCPF');
      if (mdlCPF)
        formData.cpf = mdlCPF;
            
      if (cpfRef.current)
        cpfRef.current.focus();

    }, 250);

  }, []);

  const handleChange = async (name, value, inputRef = null) => {

    const cursorPosition = inputRef ? inputRef.current.selectionStart : null;

    if (name === 'name' || name === 'nickname' || name === 'email')
      value = value.toUpperCase();

    if (name === 'cpf' || name === 'mobile')
      value = value.trim().replace(/\D/g, '');

    if (name === 'cpf') {

      const validCPF = utils.validateCPF(value);

      if (validCPF && (value != lastCPF)) {

        try {

          const response = await fetch(process.env.REACT_APP_API_ENDPOINT + 'users/lookup/' + value + '?m=1', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            }
          });
    
          if (!response.ok)
            throw new Error(response.message);
    
          const result = await response.json();
          if (!result)
            throw new Error("Unknown result");
          else if (result.status == "AlreadyExists") {

            openModal("Usuário já cadastrado.\nPor favor faça o login.", () => {
              return () => {
                window.location.href = '/login';
              };        
            });            
          }
          else if (result.status == "LookUp") {

            setFormData((prevData) => ({
              ...prevData,
              name:      result.name     ? result.name    .toUpperCase() : '',
              nickname:  result.nickname ? result.nickname.toUpperCase() : '',
              email:     result.email    ? result.email   .toUpperCase() : '',
              mobile:    result.mobile,
              birthdate: result.birthdate ? result.birthdate : '1990-01-01',
              sex:       result.sex,
              state:     result.state ? result.state.toUpperCase() : '',
              city:      result.city  ? result.city .toUpperCase() : '',
              userUUID:  result.userUUID
            }));            

            if (result.state) {

              const fc = await fetchCities(result.state);
              if (fc != null && fc.cities != null && fc.cities.length > 0) {
      
                setCityOptions(fc.cities);
                if (result.city)
                  setNextCityLookup( result.city.toUpperCase() );
              }
            }
    
            if (result.city)
              setNextCityLookup( result.city.toUpperCase() );
          }
          else if (result.status == "NotFound") {

          }
          else
            throw new Error("Result: " + result.status);
    
          setIsCPFValid( true );
          setLastCPF(value);
        }
        catch (error) {
    
          console.error('Error fetching cpf: ', error);

          setIsCPFValid( false );

          if (!validCPF)
            setLastCPF(value);
        }
      }
      else {

        setIsCPFValid( validCPF );
      }
    }

    if (name === 'state') {

      {
        const result = await fetchCities(value);
        if (result != null && result.cities != null && result.cities.length > 0) {

          setCityOptions(result.cities);

          if (nextCityLookup) {

            formData.city = nextCityLookup;
            setNextCityLookup('');
          }
        }
        else
          throw new Error('Could not retrieve cities information');
      }
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (inputRef) {

      inputRef.current.value = value;
      inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
    }
  };

  function openModal(msg, cb = null) {

    setModalMessage(msg);
    setModalCalback(cb);      

    const focusedInput = document.activeElement;
    if (focusedInput)
      focusedInput.blur();
  }

  const handleConfirmModalClose = () => {
    
    setConfirmModalOpen(false);
  };

  function convertPassword(pw) {
  
    return MD5(pw + "MDL#world").toString();
  }

  const handleConfirmRegistration = async (pass) => {

    setLoading(true);

    formData.pass = convertPassword(pass);
 
    try {

      const response = await fetch(process.env.REACT_APP_API_ENDPOINT + 'users/new', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();
      if (!result) {

        throw new Error("Serviço indisponível.\nPor favor, tente novamente em alguns instantes.\Status: " + response.status);
      }
      else if (response.status == 409) {

        setModalMessage(result.msg);
        setModalCalback(() => {
          return () => {

            setLoading(false);

            localStorage.setItem('mdlCPF', formData.cpf);

            window.location.href = '/login';
          };
        });
      }
      else if (!response.ok) {

        throw new Error(result.msg);
      }
      else { //success:

        localStorage.setItem('mdlToken',  result.token);
        localStorage.setItem('mdlUserId', result.userId);
        localStorage.setItem('mdlCPF',    result.cpf);
        localStorage.setItem('mdlEmail',  result.email);
        localStorage.setItem('mdlName',   result.name );
        localStorage.setItem('mdlAdmin',  result.admin);

        localStorage.setItem('mdlCartPaid',       '');
        localStorage.setItem('mdlCartRegistered', '');

        setModalMessage("Cadastro realizado!");
        setModalCalback(() => {
          return () => {
            const loginRedirect = localStorage.getItem('loginRedirect');
            if (loginRedirect)
              window.location.href = loginRedirect;
            else if (eventUUID && eventUUID.length >= 0)
              window.location.href = '/purchase';
            else
              window.location.href = process.env.REACT_APP_LANDING_DEFAULT; //event not defined
          };
        });
      }
    }
    catch(error) {

      console.error('Error logging in: ', error.message);

      setModalMessage(error.message);
      setModalCalback(() => {
        return () => {
          setLoading(false);
        };
      });
    }    
  };  

  const handleSubmit = (e) => {

    e.preventDefault();

    if (!utils.validateCPF(formData.cpf)) {

      openModal("CPF inválido.\nPor favor confira os dados.", () => {
        return () => {
          cpfRef.current.focus();
        };        
      });      

      return;
    }

    if (!utils.validateEmail(formData.email)) {

      openModal("Email inválido.\nPor favor confira os dados x.", () => {
        return () => {
          emailRef.current.focus();
        };        
      });      

      return;
    }

    if (!utils.validateMobileBR(formData.mobile)) {

      openModal("Número de celular inválido.\nInicie com o DDD da sua região.", () => {
        return () => {
          mobileRef.current.focus();
        };        
      });      

      return;
    }

    //console.log('out: ' + JSON.stringify(formData));

    setConfirmModalOpen(true);
  };  

  const handleModalMessageClose = () => {

    setModalMessage(null);

    if (modalCallback) {

      modalCallback();
      setModalCalback(null);
    }
  };
  
  const handleBack = () => {

    window.history.back();
  }

  const bodyStyle = {
    backgroundColor: 'rgb(241 245 249)',
    margin: 0,
    padding: 0,
    minHeight: '100vh'
  };  

  return (
    <div style={bodyStyle}>
      <div className="container bg-slate-100 mx-auto p-4 max-w-screen-md">
        {loading && (<Loading />)}
        {!loading && (
          <div>
            <Logo />
            <div>
              <h1 className="text-center text-1xl font-bold mb-4">Crie seu cadastro:</h1>
            </div>

            <form onSubmit={handleSubmit} className="grid grid-cols-1 border-2 border-gray-300 rounded bg-white">

              <div className="p-2">
                <label className="block">CPF:</label>
                <input 
                  autocomplete="cpf"
                  ref={cpfRef}
                  type="text"
                  name="cpf"
                  maxLength="11"
                  placeholder="Digite o CPF"
                  className="border-2 rounded w-full p-1"
                  value={formData.cpf}
                  onChange={(e) => handleChange('cpf', e.target.value)}
                  inputMode="numeric"
                  pattern="[0-9]*"
                  required
                />
              </div>

              <div className="p-2">
                <label className="block">Apelido:</label>
                <input disabled={!isCPFValid} autocomplete="nickname" ref={nicknameRef} type="text" name="nickname" maxLength="30" placeholder="Digite apelido ou nome" className="border-2 rounded w-full p-1" value={formData.nickname} onChange={(e) => handleChange('nickname', e.target.value, nicknameRef)} required />
              </div>

              <div className="p-2">
                <label className="block">Nome completo:</label>
                <input disabled={!isCPFValid} autocomplete="name" ref={nameRef} type="text" name="name" maxLength="100" placeholder="Digite o nome completo" className="border-2 rounded w-full p-1" value={formData.name} onChange={(e) => handleChange('name', e.target.value, nameRef)} required />
              </div>

              <div className="p-2">
                <label className="block">Email:</label>
                <input disabled={!isCPFValid} autocomplete="email" ref={emailRef} type="text" name="email" maxLength="64" placeholder="Digite o email" className="border-2 rounded w-full p-1" value={formData.email} onChange={(e) => handleChange('email', e.target.value, emailRef)} required />
              </div>

              <div className="p-2">
                <label className="block">Celular:</label>
                <input disabled={!isCPFValid}
                  autocomplete="tel"
                  ref={mobileRef}
                  type="text"
                  name="mobile"
                  maxLength="15"
                  placeholder="Digite o celular com DDD"
                  className="border-2 rounded w-full p-1"
                  value={formData.mobile}
                  onChange={(e) => handleChange('mobile', e.target.value)}
                  inputMode="numeric"
                  pattern="[0-9]*"
                  required />
              </div>

              <div className="p-2">
                <label className="block">Data de nascimento:</label>
                <input disabled={!isCPFValid} autocomplete="bday" ref={birthdateRef} type="date" name="birthdate" maxLength="10" placeholder="Digita a data de nascimento" className="border-2 rounded w-full p-1" value={formData.birthdate} onChange={(e) => handleChange('birthdate', e.target.value)} required />
              </div>

              <div className="p-2">
                <label className="block">Sexo:</label>
                <select
                  disabled={!isCPFValid}
                  autocomplete="sex"
                  ref={sexRef} 
                  className="form-select border-2 rounded w-full p-1"
                  name="sex"
                  value={formData.sex}
                  onChange={(e) => handleChange('sex', e.target.value)}
                  required>
                  <option value="">Selecione</option>
                  <option value="M">MASCULINO</option>
                  <option value="F">FEMININO</option>
                </select>
              </div>              

              <div className="p-2">
                <label className="block">Estado:</label>
                <select
                  disabled={!isCPFValid}
                  autocomplete="uf"
                  ref={stateRef} 
                  className="form-select border-2 rounded w-full p-1"
                  name="state"
                  value={formData.state}
                  onChange={(e) => handleChange('state', e.target.value)}
                  required>
                  {stateOptions.map((so) => (
                  <option key={so} value={so}>
                    {so}
                  </option>
                  ))}
                </select>
              </div>              

              <div className="p-2">
                <label className="block">Município:</label>
                <select
                  disabled={!isCPFValid}
                  autocomplete="city"
                  ref={cityRef} 
                  className="form-select border-2 rounded w-full p-1"
                  name="city"
                  value={formData.city}
                  onChange={(e) => handleChange('city', e.target.value)}
                  required>
                  {cityOptions.map((co) => (
                  <option key={co} value={co}>
                    {co}
                  </option>
                  ))}
                </select>
              </div>              

              <div className="flex justify-center items-center m-2">

                <div className="flex justify-end items-center m-2">
                  <button type="submit" className={`bg-gray-400 text-white px-4 py-2 rounded text-xl font-bold`}
                    style={{ minWidth: '150px' }}
                    onClick={handleBack}
                  >
                    Voltar
                  </button>
                </div>

                <div className="flex justify-end items-center m-2">
                  <button type="submit" className={`bg-orange-400 text-white px-4 py-2 rounded text-xl font-bold ${isCPFValid ? '' : 'opacity-50 cursor-not-allowed'}`}
                    style={{ minWidth: '150px' }}
                    disabled={!isCPFValid}>
                    OK
                  </button>
                </div>

              </div>

            </form>

          </div>
        )}
        <ConfirmModal
          user={formData.cpf}
          isOpen={isConfirmModalOpen}
          onClose={handleConfirmModalClose}
          onConfirm={handleConfirmRegistration}
        /> 
        {modalMessage && (
          <ModalMessage message={modalMessage} onClose={handleModalMessageClose} />
        )}                       
      </div>
    </div>
  );
}

export default App;
