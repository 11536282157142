import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import ModalMessage from './ModalMessage'; 
import Loading      from './Loading';
import MainHeader   from './MainHeader'
import './App.css';

import * as utils from './Utils';

window.addEventListener( "pageshow", function ( event ) {
  var historyTraversal = event.persisted || ( typeof window.performance != "undefined" &&  window.performance.navigation.type === 2 );
  if ( historyTraversal ) { window.location.reload(); } });

function App() {

  const [loading,       setLoading     ] = useState(true);
  const [modalMessage,  setModalMessage] = useState(null);
  const [modalCallback, setModalCalback] = useState(null);

  const [userPurchases, setUserPurchases] = useState([]);

  const { userCPF } = useParams();

  useEffect(() => {

    if (userCPF && utils.validateCPF(userCPF)) {

      if (localStorage.getItem('mdlCPF') != userCPF) {

        localStorage.removeItem('mdlToken');
        localStorage.removeItem('mdlUserId');
        localStorage.removeItem('mdlCPF');
        localStorage.removeItem('mdlEmail');
        localStorage.removeItem('mdlName'); 
        localStorage.removeItem('mdlAdmin');
        
        localStorage.setItem('mdlCPF', userCPF);

        localStorage.setItem('loginRedirect', '/home');
        window.location.href = '/login-enter'; 
        return;   
      }
    }

    if (!localStorage.getItem('mdlToken')  ||
        !localStorage.getItem('mdlUserId') ||
        !localStorage.getItem('mdlCPF')    ||
        !localStorage.getItem('mdlEmail')  ||
        !localStorage.getItem('mdlName')) {    

      localStorage.setItem('loginRedirect', '/home');
      window.location.href = '/login';
      return;    
    }

    localStorage.setItem('landingTag', '/home');

    const loadUserPurchases = async () => {

      const mdlUserId = localStorage.getItem('mdlUserId');
      const mdlToken  = localStorage.getItem('mdlToken' );

      try {

        const response = await fetch(process.env.REACT_APP_API_ENDPOINT + 'users/' + mdlUserId + '/purchases?token=' + mdlToken, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*'
          },
        });
    
        if (!response.ok) {

          if (response.status == 404) {

            localStorage.setItem('loginRedirect', '/home');
            window.location.href = '/login';
            return;    
          }
          else
            throw new Error('Error fetching data details: ' + response.status);
        }
    
        const data = await response.json();
        if (data != null && data.status == 'OK') {

          if (data != null && data.purchases != null) {

            setUserPurchases(data);
            setLoading(false);
          }
          else {
  
            throw new Error('Could not retrieve user purchases');
          }
        }
        else {

          setModalMessage(data.msg);
          setModalCalback(() => {
            return () => {
              window.location.reload();
            };
          });
        }
      }
      catch(error) {

        console.log("Error: " + error.message);

        setModalMessage("Serviço indisponível.\nPor favor, tente novamente em alguns instantes.");
        setModalCalback(() => {
          return () => {
            window.location.reload();
          };
        });
      }
    };

    loadUserPurchases();

  }, []);

  const handleStartButtonClick = () => {

    localStorage.setItem('loginRedirect', '/purchase');
    window.location.href = '/login';
  }

  const handleModalMessageClose = () => {

    setModalMessage(null); // Clear the modal message

    if (modalCallback) {

      modalCallback();
      setModalCalback(null);
    }
  };  

  /*
  const handleMyRegistration = () => {

    window.location.href = '/registration';
  };
  const handleMyPurchases = () => {

    window.location.href = '/wallet';
  };

  const handleOnlineRankings = () => {

    window.location.href = '/placar';
  };

  const handleEventsOnSale = () => {

    //window.location.href = '/events';
  };
  */

  const bodyStyle = {
    backgroundColor: 'rgb(241 245 249)',
    margin: 0,
    padding: 0,
    minHeight: '100vh'
  };

  const handleCartView = async (eventUUID, cartUUID) => {

    setLoading(true);

    const data = {
      token: localStorage.getItem('mdlToken')
    }    
    
    const printWindow = window.open('', '_blank');

    try {

      const response = await fetch(process.env.REACT_APP_API_ENDPOINT + 'cart/' + cartUUID + '/receipt/html', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok)
        throw new Error(response.message);

      const result = await response.json();
      if (!result || result.status != 'OK')
        throw new Error("Result: " + result.msg);

      printWindow.document.write(result.receiptHTML);
      printWindow.document.close();
      printWindow.focus();
      printWindow.print();
      setTimeout(() => { printWindow.close();  }, 250);

      setLoading(false);
    }
    catch (error) {

      console.error('Error printing receipt: ', error);
      printWindow.close(); 

      setModalMessage("Serviço indisponível.\nPor favor, tente novamente em alguns instantes.");
      setModalCalback(() => {
        return () => {
          setLoading(false);
        };
      });
    }
  }

  const handleCartRegister = (eventUUID, cartUUID) => {

    localStorage.setItem('eventUUID', eventUUID)
    localStorage.setItem('cartUUID',  cartUUID);
    window.location.href = '/participants';
  }

  const handlePayNow = (eventUUID, cartUUID, pix) => {

    localStorage.setItem('eventUUID', eventUUID)
    localStorage.setItem('cartUUID',  cartUUID);
    localStorage.setItem('pixTxid',   pix.txid);
    window.location.href = '/pay-installment';
  }

  return (
    <div style={bodyStyle}>
      <div className="container bg-slate-100 mx-auto p-4 max-w-screen-md">
        {loading && (<Loading />)}
        {!loading && (
          <div>

            <MainHeader label={"Área do laçador".toUpperCase()} />

            <div className="mt-16">

              <div className="flex justify-center items-center mb-4"
                onClick={handleStartButtonClick}
              >
              </div>

              <div className="mt-8">
                <div className="text-center text-1xl m-4 font-bold">{"Olá, " + localStorage.getItem('mdlName')}</div>
              </div>

              <div>
                <p className="text-center text-1xl m-2">Suas inscrições:</p>
              </div>

              <ul className="grid grid-cols-1 -mx-2 items-end justify-end">
                {userPurchases.purchases.map((item) => {

                  return(
                    
                    <li 
                      className={"flex flex-col justify-between items-center mb-6 bg-white p-4 rounded-lg shadow-md border-gray-300 border-2"}
                      style={{ userSelect: 'none' }}
                    >
                      <div>

                        <div className="flex items-center justify-center">
                          <div>
                            <p className="text-center text-s font-semibold mb-2">{item.eventName.toUpperCase()}</p>
                          </div>
                        </div>

                        <div className="flex items-center justify-center">
                          <button className={`bg-orange-400 text-white text-s font-bold px-4 py-2 mb-2 rounded flex items-center justify-center` + (item.cartStatus == 'Registered' ? '' : ' animate-glowing-green')}
                            onClick={() => (item.cartStatus == 'Registered' ? handleCartView(item.eventUUID, item.cartUUID) : handleCartRegister(item.eventUUID, item.cartUUID)) } style={{ minWidth: '300px', maxHeight: '50px' }}>
                            {item.cartStatus == 'Registered' ? "Gerar comprovante" : "Finalizar inscrição"}
                          </button>                  
                        </div>

                      </div>

                      {item.payment.pix.length > 0 &&
                      <div>
                        <p className="text-lg mt-4 mb-2">Pagamentos:</p>
                      </div>}

                      <ul className="grid grid-cols-1 text-center">
                        { item.payment.pix.map((pix) => {

                          return (
                            <li className="border mb-2 p-4 rounded bg-orange-50 grid grid-cols-2 gap-4 items-center">

                            <div className="flex items-center justify-center">
                              <div>
                                <p className="text-s mb-0 ">Valor:</p>
                                <p className="text-s font-semibold mb-2">{utils.formatCurrency(pix.chargedValue)}</p>
                                <p className="text-s mb-0 ">{pix.paidOn.length > 0 ? "Pago em:" : "Vencimento em:"}</p>
                                <p className="text-s font-semibold mb-0">{utils.formatDate(pix.paidOn.length > 0 ? pix.paidOn : pix.installmentsOn, false)}</p>
                              </div>
                            </div>

                            <div className="items-center justify-center">
                              {pix.installmentNum > 0 && <p className="text-s mb-2">Parcela {pix.installmentNum}/{item.payment.installmentsInfo.numInstallments}</p>}
                              <button 
                                className={`px-4 py-2 rounded font-bold ${pix.paidOn.length == 0 ? 'bg-orange-400 text-white' + (pix.payNow > 0 ? ' animate-glowing-green' : '') : 'border border-gray-300 bg-gray-200 text-black opacity-100 cursor-not-allowed'}`}
                                onClick={() => handlePayNow(item.eventUUID, item.cartUUID, pix)} disabled={pix.paidOn.length > 0} style={{ minWidth: '150px' }}>
                                {pix.paidOn.length == 0 ? 'Pagar agora' : 'Quitada'}
                              </button>                            
                            </div>                      
      
                          </li>
                        )})}
                      </ul>

                    </li>
                  )}
                )}
              </ul>
              
              { /*
              <ul className="grid grid-cols-1 mt-8 -mx-2">
                <li 
                  className={"flex flex-col justify-between items-left mb-6 bg-white p-4 rounded-lg shadow-md border-gray-300 border-2"}
                  style={{ userSelect: 'none' }}
                >
                  <div>
                    <p className="text-center text-1xl m-2">Selecione uma opção:</p>
                  </div>

                  <div className="mt-4 flex justify-center items-center text-l font-bold mb-2">
                    <button className="bg-orange-400 text-white px-4 py-2 rounded text-xl font-bold"
                      style={{ minWidth: '340px' }}
                      onClick={handleMyPurchases}>
                      Minhas inscrições
                    </button>
                  </div>

                  <div className="mt-4 flex justify-center items-center text-l font-bold mb-2">
                    <button className="bg-orange-400 text-white px-4 py-2 rounded text-xl font-bold"
                      style={{ minWidth: '340px' }}
                      onClick={handleEventsOnSale}>
                      Eventos à venda
                    </button>
                  </div>

                  <div className="mt-4 flex justify-center items-center text-l font-bold mb-2">
                    <button className="bg-orange-400 text-white px-4 py-2 rounded text-xl font-bold"
                      style={{ minWidth: '340px' }}
                      onClick={handleOnlineRankings}>
                      Placar online
                    </button>
                  </div>

                  <div className="mt-4 flex justify-center items-center text-l font-bold mb-2">
                    <button className="bg-orange-400 text-white px-4 py-2 rounded text-xl font-bold"
                      style={{ minWidth: '340px' }}
                      onClick={handleMyRegistration}>
                      Meus dados
                    </button>
                  </div>

                </li>

              </ul>
              */ }

            </div>

          </div>
        )}
        {modalMessage && (
          <ModalMessage message={modalMessage} onClose={handleModalMessageClose} />
        )}                       

      </div>
    </div>
  );
}

export default App;
