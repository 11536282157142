//import logo from './logo.svg';

import React, { useState, useEffect, useRef } from 'react';

import ConfirmModal from './ConfirmModal-generic';
import ModalMessage from './ModalMessage'; 
import Loading      from './Loading';

import * as utils from './Utils';
import { MD5 } from 'crypto-js';

import './App.css';

const Logo = () => {

  return (
    <div className="flex justify-center items-center mb-2">
      <img src="../assets/mdl-logo-circle-bg-white.png" alt="Logo" className="max-w-full h-auto" style={{ maxWidth: '100px' }}/>
    </div>
  );
};

function App() {

  const [loading, setLoading] = useState(true);

  const [modalMessage,  setModalMessage] = useState(null);
  const [modalCallback, setModalCalback] = useState(null);

  const [confirmMessage,      setConfirmMessage     ] = useState(null);
  const [confirmLabelOk,      setConfirmLabelOk     ] = useState(null);
  const [confirmLabelCancel,  setConfirmLabelCancel ] = useState(null);
  const [confirmHandleOk,     setConfirmHandleOk    ] = useState(null);
  const [confirmHandleCancel, setConfirmHandleCancel] = useState(null);

  const eventUUID = localStorage.getItem('eventUUID');

  const adminCheck = (window.location.hash.substring(1) && window.location.hash.substring(1) == "admin" ? 1 : 0);

  useEffect(() => {

    if (localStorage.getItem('mdlToken') &&
        localStorage.getItem('mdlEmail') &&
        localStorage.getItem('mdlName')) {

      if (adminCheck == 0 || (adminCheck != 0 && localStorage.getItem('mdlAdmin'))) {

        doLoginWithToken();
      }
      else {

        setLoading(false);
      }
    }
    else {

      setLoading(false);
    }

  }, []);

  const doLoginWithToken = async (e) => {

    setLoading(true);

    const login = {
      token:      localStorage.getItem('mdlToken'),
      adminCheck: adminCheck
    }

    try {

      const queryString = new URLSearchParams(login).toString();

      const response = await fetch(process.env.REACT_APP_API_ENDPOINT + 'users/login/token?' + queryString, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(login),
      });

      const result = await response.json();
      if (!result)
        throw new Error("Serviço indisponível.\nPor favor, tente novamente em alguns instantes.\Status: " + response.status);
      else if (!response.ok)
        throw new Error(result.msg);

      { //store user info:

        localStorage.setItem('mdlToken',  result.token);
        localStorage.setItem('mdlUserId', result.userId);
        localStorage.setItem('mdlCPF',    result.cpf);
        localStorage.setItem('mdlEmail',  result.email);
        localStorage.setItem('mdlName',   result.name);
        localStorage.setItem('mdlAdmin',  result.admin);

        if (eventUUID && eventUUID.length >= 0) {

          localStorage.setItem('mdlCartPaid', '');
          for(const cp of result.cartsPaid) {

            if (cp.eventUUID == eventUUID) {

              localStorage.setItem('mdlCartPaid', cp.cartUUID);
              //console.log("mdlCartPaid = " + cp.cartUUID);
              break;
            }
          }

          localStorage.setItem('mdlCartRegistered', '');
          for(const cr of result.cartsRegistered) {

            if (cr.eventUUID == eventUUID) {

              localStorage.setItem('mdlCartRegistered', cr.cartUUID);
              break;
            }
          }
        }
      }

      { //setup confirmation dialog:

        setConfirmMessage("Você confirma ser este usuário?\n\n" + localStorage.getItem('mdlName') + "\n\n");
        setConfirmLabelOk("Sim");
        setConfirmLabelCancel("Não");
        setConfirmHandleOk(() => {
          return () => {

            setConfirmMessage(null);

            const loginRedirect = localStorage.getItem('loginRedirect');
            if (loginRedirect)
              window.location.href = loginRedirect;
            else if (eventUUID && eventUUID.length >= 0)
              window.location.href = '/purchase';
            else {
              setModalMessage("Login realizado!");
              setModalCalback(() => {
                return () => {
                  window.location.href = process.env.REACT_APP_LANDING_DEFAULT; //event not defined
                };
              });
            }
          };  
        });
        setConfirmHandleCancel(() => {
          return () => {
            localStorage.removeItem('mdlToken');
            localStorage.removeItem('mdlUserId');
            localStorage.removeItem('mdlCPF');
            localStorage.removeItem('mdlEmail');
            localStorage.removeItem('mdlName'); 
            localStorage.removeItem('mdlAdmin');  
            window.location.reload();
          };
        });
      }
    }
    catch (error) {

      console.error('Error logging in: ', error.message);

      setModalMessage(error.message);
      setModalCalback(() => {
        return () => {
          localStorage.removeItem('mdlToken');
          localStorage.removeItem('mdlUserId');
          localStorage.removeItem('mdlCPF');
          localStorage.removeItem('mdlEmail');
          localStorage.removeItem('mdlName'); 
          localStorage.removeItem('mdlAdmin');  
          window.location.reload();
        };
      });
    }    
  }

  const handleRegister = (e) => {

    e.preventDefault();
    window.location.href = '/registration';
  };   

  const handleLoginEnter = (e) => {

    e.preventDefault();
    window.location.href = '/login-enter' + (adminCheck ? '#admin' : '');
  };   

  const handleCancel = (e) => {

    e.preventDefault();

    //window.history.back();
    window.location.href = '/';
  };  

  const handleModalMessageClose = () => {

    setModalMessage(null);

    if (modalCallback) {

      modalCallback();
      setModalCalback(null);
    }
  }; 

  const bodyStyle = {
    backgroundColor: 'rgb(241 245 249)',
    margin: 0,
    padding: 0,
    minHeight: '100vh'
  };  

  return (
    <div style={bodyStyle}>
      <div className="container bg-slate-100 mx-auto p-4 max-w-screen-md">
        {loading && (<Loading />)}
        {!loading && (
          <div>
            <Logo />
            <div>
              <h1 className="text-center text-1xl font-bold mb-4">Cadastre-se ou faça seu login:</h1>
            </div>

            <div className="mt-4 flex justify-center items-center text-l font-bold mb-4">
              <button className="bg-orange-400 text-white px-4 py-2 rounded text-xl font-bold"
                onClick={handleRegister}
                style={{ minWidth: '340px' }}>
                CADASTRE-SE
              </button>
            </div>

            <div className="mt-4 flex justify-center items-center text-l font-bold mb-4">
              <button className="bg-orange-400 text-white px-4 py-2 rounded text-xl font-bold"
                onClick={handleLoginEnter}
                style={{ minWidth: '340px' }}>
                JÁ SOU CADASTRADO
              </button>
            </div>

            <div className="mt-4 flex justify-center items-center text-l font-bold mb-4">
              <button
                className="bg-gray-400 text-white px-4 py-2 rounded text-xl font-bold mr-2"
                onClick={handleCancel}
                style={{ minWidth: '340px' }}>
                Voltar
              </button>            
            </div>

          </div>
        )}
        {modalMessage && (
          <ModalMessage message={modalMessage} onClose={handleModalMessageClose} />
        )}
        {confirmMessage && (
          <ConfirmModal message={confirmMessage} okLabel={confirmLabelOk} cancelLabel={confirmLabelCancel} onOk={confirmHandleOk} onCancel={confirmHandleCancel} />
        )}
      </div>
    </div>
  );
}

export default App;
