import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.css';
import GoogleTagManager from './components/GoogleTagManager';
import AppLanding        from './App-00-landing';
import AppLogin          from './App-00-login';
import AppLoginEnter     from './App-00-login-enter';
import AppRegistration   from './App-00-registration';
import AppRecover        from './App-00-recover';
import AppRecoverEmail   from './App-00-recover-email';
import AppRecoverMobile  from './App-00-recover-mobile';
import AppRedefine       from './App-00-redefine';
import AppPurchase       from './App-01-purchase';
import AppInstallments   from './App-02-installments';
import AppPayment        from './App-03-payment';
import AppParticipants   from './App-04-participants';
import AppSuccess        from './App-05-success';
import AppMyNumber       from './App-06-my-number';
import AppAdminMenu      from './App-100-admin-menu';
import AppAdminEvents    from './App-101-admin-events';
import AppRankings       from './App-200-rankings';
import AppRankingsFilter from './App-201-rankings-filter';
import AppRankingsDetail from './App-202-rankings-detail';
import AppHome           from './App-300-user-home';
import AppPayInstallment from './App-301-user-pay-installment';

const getSubdomain = () => {
  const hostname = window.location.hostname; //ex: "sub1.example.com"
  const parts = hostname.split('.');
  return parts.length > 2 ? parts[0] : null;
};

const App = () => {

  const subdomain = getSubdomain();

  return(
    <div>
      <GoogleTagManager />
      <Routes>

        {subdomain === "placar" && 
          (<Route path="/" Component={AppRankings} />)}

        {subdomain !== "placar" && 
          (<Route path="/" Component={AppLanding} />)}

        <Route path="/:eventName"               Component={AppLanding}        />
        <Route path="/home"                     Component={AppHome}           />
        <Route path="/home/:userCPF"            Component={AppHome}           />
        <Route path="/pay-installment"          Component={AppPayInstallment} />
        <Route path="/login"                    Component={AppLogin}          />
        <Route path="/login-enter"              Component={AppLoginEnter}     />
        <Route path="/registration"             Component={AppRegistration}   />
        <Route path="/recover"                  Component={AppRecover}        />
        <Route path="/recover-email"            Component={AppRecoverEmail}   />
        <Route path="/recover-mobile"           Component={AppRecoverMobile}  />
        <Route path="/redefine"                 Component={AppRedefine}       />
        <Route path="/purchase"                 Component={AppPurchase}       />
        <Route path="/installments"             Component={AppInstallments}   />
        <Route path="/payment"                  Component={AppPayment}        />
        <Route path="/participants"             Component={AppParticipants}   />
        <Route path="/success"                  Component={AppSuccess}        />
        <Route path="/numero"                   Component={AppMyNumber}       />
        <Route path="/admin"                    Component={AppAdminMenu}      />
        <Route path="/admin-events"             Component={AppAdminEvents}    />
        <Route path="/placar"                   Component={AppRankings}       />
        <Route path="/placar/filter/:eventUUID" Component={AppRankingsFilter} />
        <Route path="/placar/detail/:catUUID"   Component={AppRankingsDetail} />
      </Routes>
    </div>
  )
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
 //<React.StrictMode>
    <BrowserRouter>
       <App />
    </BrowserRouter>
 //</React.StrictMode>
);

//import reportWebVitals       from './reportWebVitals';
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();

export default App;

