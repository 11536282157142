import React, { useState, useEffect, useRef } from 'react';

import ModalMessage    from './ModalMessage';
import Loading         from './Loading';
import InactivityTimer from './components/InactivityTimer';
import RankingsHeader  from './RankingsHeader'

import * as utils from './Utils';

import './App.css';

const fetchEvents = async () => {

  try {

    const response = await fetch(process.env.REACT_APP_API_ENDPOINT + 'events/?rankings=1', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
    });

    if (!response.ok)
      throw new Error(response.message);

    const data = await response.json();
    return data;
  }
  catch (error) {

    console.error('Error fetching events: ', error);
    throw error;
  }
};

function App() {

  const [loading, setLoading] = useState(true);
  const [events,  setEvents ] = useState([]);

  const [modalMessage,  setModalMessage] = useState(null);
  const [modalCallback, setModalCalback] = useState(null);

  const bodyStyle = {

    backgroundColor: 'rgb(241 245 249)',
    margin: 0,
    padding: 0,
    minHeight: '100vh'
  };  

  useEffect(() => {

    const loadEvents = async () => {

      try {

        const events = await fetchEvents();

        if (events.status == 'OK') {

          if (events != null && events.items != null) {

            setEvents(events);
            setLoading(false);
          }
          else {
  
            throw new Error('Could not retrieve events information');
          }
        }
        else {

          setModalMessage(events.msg);
          setModalCalback(() => {
            return () => {
              window.location.reload();
            };
          });
        }
      }
      catch(error) {

        console.log("Error: " + error.message);

        setModalMessage("Serviço indisponível.\nPor favor, tente novamente em alguns instantes.");
        setModalCalback(() => {
          return () => {
            window.location.reload();
          };
        });
      }
    };

    loadEvents();

  }, []);

  const handlePageTimeout = async () => {

    const events = await fetchEvents();
    if (events.status == 'OK' && events != null && events.items != null)
      setEvents(events);
    else
      window.location.reload();
  };  

  const handleModalMessageClose = () => {

    setModalMessage(null); // Clear the modal message
  
    if (modalCallback) {
  
      modalCallback();
      setModalCalback(null);
    }
  };

  const handleEventButtonClick = async (eventUUID) => {

    console.log('Rankings for: ', eventUUID);
    window.location.href = '/placar/filter/' + eventUUID;
  }

  const formatDate = (dateString) => {

    if (dateString && dateString.length >= 19) {

      const date    = new Date(dateString);
      const day     = String(date.getDate()).padStart(2, '0');
      const month   = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
      const year    = date.getFullYear();
      const hours   = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
      return `${day}/${month}/${year}\nàs ${hours}:${minutes}:${seconds}`;
    }
    else
      return dateString;
  };

  const LiveIcon = ({ msg }) => {
    return (
      <div className="relative inline-block">
        {(msg > 0 || msg.length > 0) > 0 && (
          <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold text-white bg-red-500 rounded-full transform translate-x-1/4 -translate-y-7">
            {msg}
          </span>
        )}
      </div>
    );
  };

  return (
    <div style={bodyStyle}>
        <InactivityTimer 
          timeoutDuration={ 1 * 60 * 1000 /* 1 min */ }
          onTimeout={handlePageTimeout}
        />
      <div className="container bg-slate-100 mx-auto p-4 max-w-screen-md">
        {loading && (<Loading />)}
        {!loading && (
          <div>

            <div className="flex justify-center items-center mb-4">
              <RankingsHeader label="PLACAR MUNDO DO LAÇO"/>
            </div>

            {events.items.length==0&&(<div className="mt-20">
              <h1 className="text-center text-1xl mb-6">Nenhum evento encontrado.</h1>
            </div>
            )}

            <ul className="grid grid-cols-1 mt-20 -mx-2">
              {events.items.map((item) => (
                <li 
                  className={"flex flex-col justify-between items-left mb-6 bg-white p-4 rounded-lg shadow-md border-gray-300 border-2"}
                  style={{ userSelect: 'none' }}
                >
                  {item.rankingsLiveNow>0&&<LiveIcon msg={"Ao vivo"} />}

                  <div>
                    <p className="text-lg font-semibold mb-0">{item.name.toUpperCase()}</p>
                  </div>

                  <ul className="mt-4">

                    <li className="border mb-2 p-4 rounded bg-orange-50 grid grid-cols-2 gap-4 items-center">

                      <div className="flex items-center justify-center">
                        <div>
                          <p className="text-s mb-2 ">Última atualização:</p>
                          <div className="mb-2">
                            <p className="text-s font-semibold mb-0">{formatDate(item.rankingsUpdatedOn)}</p>
                          </div>
                        </div>
                      </div>

                      <div className="flex items-center justify-end">
                        <button className="bg-orange-400 text-white text-s font-bold px-4 py-2 rounded"
                          onClick={() => handleEventButtonClick(item.eventUUID)} style={{ minWidth: '150px', maxWidth: '250px', maxHeight: '50px' }}>
                          Ver placar
                        </button>                  
                      </div>                      

                    </li>
                    
                  </ul>

                </li>
              ))}
            </ul>

            <div className="mb-16">
            </div>

          </div>
        )}
        {modalMessage && (
          <ModalMessage message={modalMessage} onClose={handleModalMessageClose} />
        )}
      </div>
    </div>
  );
}

export default App;