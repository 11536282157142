//import logo from './logo.svg';

import React, { useState, useEffect, useRef } from 'react';

import ModalMessage from './ModalMessage'; 
import Loading      from './Loading';

import * as utils from './Utils';
import { MD5 } from 'crypto-js';

import './App.css';

const Logo = () => {

  return (
    <div className="flex justify-center items-center mb-2">
      <img src="../assets/mdl-logo-circle-bg-white.png" alt="Logo" className="max-w-full h-auto" style={{ maxWidth: '100px' }}/>
    </div>
  );
};

function convertPassword(pw) {
  
  return MD5(pw + "MDL#world").toString();
}

function App() {

  const [loading,  setLoading  ] = useState(true);
  const [formData, setFormData ] = useState({
    cpf: '',
    password: '',
  });

  const [modalMessage,  setModalMessage] = useState(null);
  const [modalCallback, setModalCalback] = useState(null);

  const [isLoginValid, setIsLoginValid] = useState(true);

  const cpfRef    = useRef(null);
  const passRef   = useRef(null);
  const submitRef = useRef(null);

  const cartUUID  = localStorage.getItem('cartUUID' );
  const eventUUID = localStorage.getItem('eventUUID');

  const adminCheck = (window.location.hash.substring(1) && window.location.hash.substring(1) == "admin" ? 1 : 0);

  useEffect(() => {

    setLoading(false);

    //setIsLoginValid( utils.validateCPF(formData.cpf) && utils.validatePassword(formData.password) );

    setTimeout(() => { 

      const mdlCPF = localStorage.getItem('mdlCPF');
      if (mdlCPF) {

        formData.cpf = mdlCPF;
        cpfRef.current.value = mdlCPF; 
      }
      
      if (cpfRef.current)
        cpfRef.current.focus();

    }, 250);

  }, []);

  const handleChange = (name, value, inputRef = null) => {

    const cursorPosition = inputRef ? inputRef.current.selectionStart : null;

    if (name === 'cpf')
      value = value.trim().replace(/\D/g, '');

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (inputRef) {

      inputRef.current.value = value;
      inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
    }

    //setIsLoginValid( utils.validateCPF(formData.cpf) && utils.validatePassword(formData.password) );
  };

  function openModal(msg, cb = null) {

    setModalMessage(msg);
    setModalCalback(cb);      

    const focusedInput = document.activeElement;
    if (focusedInput)
      focusedInput.blur();
  }

  const handleKeyDown = (e) => {

    if (e.key === 'Enter') {

      e.preventDefault();
      submitRef.current.click();
    }
  };  

  const handleRegister = (e) => {

    e.preventDefault();

    localStorage.setItem('mdlCPF', formData.cpf);

    window.location.href = '/registration';
  };   

  const handleForgotPW = (e) => {

    e.preventDefault();

    localStorage.setItem('mdlCPF', formData.cpf);

    window.location.href = '/recover';
  };

  const handleCancel = (e) => {

    e.preventDefault();

    localStorage.setItem('mdlCPF', formData.cpf);

    window.location.href = '/login';
  };

  const handleSubmit = async (e) => {

    e.preventDefault();

    if (!utils.validateCPF(formData.cpf)) {

      openModal("CPF inválido.\nPor favor confira os dados.", () => {
        return () => {
          cpfRef.current.focus();
        };        
      });      

      return;
    }

    if (!utils.validatePassword(formData.password)) {

      openModal("Senha inválida.\nPor favor confira os dados.", () => {
        return () => {
          passRef.current.focus();
        };        
      });      

      return;
    }

    //console.log('out: ' + JSON.stringify(formData));

    setLoading(true);

    const login = {
      cpf:        formData.cpf,
      pass:       convertPassword(formData.password),
      adminCheck: adminCheck
    }

    try {

      const queryString = new URLSearchParams(login).toString();

      const response = await fetch(process.env.REACT_APP_API_ENDPOINT + 'users/login/userpass?' + queryString, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(login),
      });

      const result = await response.json();
      if (!result)
        throw new Error("Serviço indisponível.\nPor favor, tente novamente em alguns instantes.\Status: " + response.status);
      else if (!response.ok)
        throw new Error(result.msg);

      { //store user info:

        localStorage.setItem('mdlToken',  result.token);
        localStorage.setItem('mdlUserId', result.userId);
        localStorage.setItem('mdlCPF',    result.cpf);
        localStorage.setItem('mdlEmail',  result.email);
        localStorage.setItem('mdlName',   result.name);
        localStorage.setItem('mdlAdmin',  result.admin);

        localStorage.setItem('mdlCartPaid', '');
        for(const cp of result.cartsPaid) {

          if (cp.eventUUID == eventUUID) {

            localStorage.setItem('mdlCartPaid', cp.cartUUID);
            //console.log("mdlCartPaid = " + cp.cartUUID);
            break;
          }
        }

        localStorage.setItem('mdlCartRegistered', '');
        for(const cr of result.cartsRegistered) {

          if (cr.eventUUID == eventUUID) {

            localStorage.setItem('mdlCartRegistered', cr.cartUUID);
            break;
          }
        }
      }

      setModalMessage("Login realizado!");
      setModalCalback(() => {
        return () => {
          const loginRedirect = localStorage.getItem('loginRedirect');
          if (loginRedirect)
            window.location.href = loginRedirect;
          else if (eventUUID && eventUUID.length >= 0)
            window.location.href = '/purchase';
          else
            window.location.href = process.env.REACT_APP_LANDING_DEFAULT; //event not defined
        };
      });
    }
    catch (error) {

      console.error('Error logging in: ', error.message);

      setModalMessage(error.message);
      setModalCalback(() => {
        return () => {
          setLoading(false);
        };
      });
    }    
  };  

  const handleModalMessageClose = () => {

    setModalMessage(null);

    if (modalCallback) {

      modalCallback();
      setModalCalback(null);
    }
  }; 

  const bodyStyle = {
    backgroundColor: 'rgb(241 245 249)',
    margin: 0,
    padding: 0,
    minHeight: '100vh'
  };  

  return (
    <div style={bodyStyle}>
      <div className="container bg-slate-100 mx-auto p-4 max-w-screen-md">
        {loading && (<Loading />)}
        {!loading && (
          <div>
            <Logo />
            <div>
              <h1 className="text-center text-1xl font-bold mb-4">Cadastre-se ou faça seu login:</h1>
            </div>

            <form 
              onSubmit={handleSubmit}
              onKeyDown={handleKeyDown}
              className="grid grid-cols-1 rounded bg-white border-gray-300 border-2 mt-8">

              <div className="p-2">
                <label className="block">Usuário (CPF):</label>
                <input ref={cpfRef} type="text" id="username" name="username" maxLength="11" placeholder="Digite seu CPF" className="border-2 rounded w-full p-1 mb-2" value={formData.cpf}
                 onChange={(e) => handleChange('cpf', e.target.value)}
                 onBlur={(e) => handleChange('cpf', e.target.value)}
                 onFocus={(e) => handleChange('cpf', e.target.value)}
                 inputMode="numeric"
                 pattern="[0-9]*"
                 required />
                <label className="block">Senha:</label>
                <input ref={passRef} type="password" name="password" maxLength="30" placeholder="Digite a senha" className="border-2 rounded w-full p-1" value={formData.password}
                 onChange={(e) => handleChange('password', e.target.value)}
                 onBlur={(e) => handleChange('password', e.target.value)}
                 onFocus={(e) => handleChange('password', e.target.value)}
                 required />
              </div>

              <div className="flex justify-end items-center">
                <button className=" text-black rounded text-s mb-4 mr-4" onClick={handleForgotPW} style={{ minWidth: '150px' }}>
                  Esqueci minha senha
                </button>
              </div>

              <div className="flex justify-center items-center m-2">

                <button
                  className="bg-gray-400 text-white px-4 py-2 rounded text-xl font-bold mr-2"
                  onClick={handleCancel}
                  style={{ minWidth: '150px' }}>
                  Voltar
                </button>

                <button
                  type="submit"
                  ref={submitRef}
                  className={`bg-orange-400 text-white px-4 py-2 rounded text-xl font-bold ml-2 ${isLoginValid ? '' : 'opacity-50 cursor-not-allowed'}`}
                  onClick={handleSubmit}
                  disabled={!isLoginValid}
                  style={{ minWidth: '150px' }}>
                  ENTRAR
                </button>

              </div>

            </form>

          </div>
        )}
        {modalMessage && (
          <ModalMessage message={modalMessage} onClose={handleModalMessageClose} />
        )}                       
      </div>
    </div>
  );
}

export default App;
